import {Col} from "react-bootstrap";
import {toTitleCase} from "../../../../utils/helpers/logicHelper";
import Gravatar from "../../../../assets/images/pngs/gravatar.png";

type Props = {
  profile: Pick<IPartnerProfile, 'id' | 'user' | 'dynamic_fields'>
}

const LoanRequestCard = ({profile}: Props) => (
  <Col md={5} xl={4} className="h-auto">
    <div className="card">
      <div className="card-body p-0">
        <div className="user-profile-img">
          <img src={Gravatar} className="profile-img rounded-top" style={{height: '80px'}} alt=""/>
          <div className="overlay-content rounded-top"/>
        </div>
        <div className="px-4 pt-0">
          <div className="mt-n5 position-relative text-center border-bottom pb-3">
            <img src={Gravatar} alt="" className="avatar-xl rounded-circle img-thumbnail"/>
            <div className="mt-3">
              <h5 className="mb-1">
                {toTitleCase(profile?.user?.first_name)} {toTitleCase(profile?.user?.last_name)}
              </h5>
              <span className={`badge rounded-pill bg-primary mx-1 bg-primary`}>
                {profile?.user?.email}
              </span>
            </div>
          </div>
          <div className="table-responsive mt-3 pb-3">
            <table className="table align-middle table-sm table-nowrap table-borderless mb-0">
              <tbody>
              <tr>
                <th className="fw-bold">Requester First Name</th>
                <td className="text-muted">{toTitleCase(profile?.user?.first_name) ?? ""}</td>
              </tr>
              <tr>
                <th className="fw-bold">Requester Last Name</th>
                <td className="text-muted">{toTitleCase(profile?.user?.last_name) ?? ""}</td>
              </tr>
              <tr>
                <th className="fw-bold">Email</th>
                <td className="text-muted">{profile?.user?.email ?? '-'}</td>
              </tr>
              <tr>
                <th className="fw-bold">Phone</th>
                <td className="text-muted">{profile?.user?.phone ?? '-'}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Col>
);

export default LoanRequestCard;
