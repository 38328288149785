import {useState} from "react";
import {useSelector} from "react-redux";
import moment from "moment";
import toast from "react-hot-toast";

import PageLayout from "../../../components/layouts/PageLayout";
import CreateSignatoryModal from "../../../components/modals/CreateSignatoryModal";
import ToolTipComponent from "../../../components/modules/ToolTipComponent";
import {toTitleCase} from "../../../utils/helpers/logicHelper";
import {MenuType} from "../../../utils/helpers/constants";
import {
  useGetSignatoriesQuery,
  useGetStaffQuery,
  useUpdateSignatoryMutation
} from "../../../store/slices/backoffice.slice";


const Signatories = () => {
  const profile: IProfile = useSelector((state: IStoreState) => state.auth.userProfile)
  const [signatoryRender, setSignatoryRender] = useState<boolean>(false)
  const {data: staff, isLoading} = useGetStaffQuery("")
  const {data: signatories, isLoading: loadingSignatories, isSuccess} = useGetSignatoriesQuery('');
  const [updateSignatoryMutation] = useUpdateSignatoryMutation();

  const confirmFinance = async (user: ISignatory): Promise<void> => {
    try {
      let filter: string = user.id, body: IUpdateSignatoryRequest = {accounts: !user.accounts}
      await updateSignatoryMutation({filter, body}).unwrap();
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  const addSignatoryModal = signatoryRender &&
		<CreateSignatoryModal staff={staff.data} signatories={signatories.data} onchange={() => setSignatoryRender(false)}/>

  return (
    <PageLayout active={MenuType.STAFF} title="Staff - Voucher Signatories" loading={isLoading || loadingSignatories}>
      {addSignatoryModal}
      {isSuccess &&
				<div className="container-fluid">
					<div className="d-flex flex-wrap justify-content-end gap-2 mb-3">
						<button className="btn btn-primary" onClick={() => setSignatoryRender(true)}>
							<i className="bx bx-plus me-1"></i> Add Signatory
						</button>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-nowrap align-middle">
											<thead className="table-light">
											<tr>
												<th scope="col">Name</th>
												<th scope="col">Email</th>
												<th scope="col">Date Onboarded</th>
												<th scope="col">Status</th>
                        {profile?.permissions?.edit_permission && <th scope="col">Action</th>}
											</tr>
											</thead>
											<tbody>
                      {signatories.data.map((item: ISignatory, index: number) =>
                        <tr key={index}>
                          <td width="25%">
                            <span>{toTitleCase(item.first_name)} {toTitleCase(item.last_name)}</span>
                          </td>
                          <td width="25%">{item.email}</td>
                          <td width="20%">{moment(item.created_at).format('LLL')}</td>
                          <td width="20%">
                            <span
                              className={`badge rounded-pill mx-1 ${staff?.data.find((each: IProfile): boolean => each.id === item.user_id) ? 'bg-success' : 'bg-danger'}`}>
                              {staff?.data.find((each: IProfile): boolean => each.id === item.user_id) ? 'Staff' : 'Audit'}
                            </span>
                            <span className={`badge rounded-pill mx-1 ${item.accounts && 'bg-info'}`}>
                              {item.accounts && 'Finance team'}
                            </span>
                          </td>
                          {profile?.permissions?.edit_permission && profile.is_superadmin &&
														<td width="10%">
															<ul className="list-inline mb-0">
																<li className="list-inline-item">
																	<ToolTipComponent text="Set finance option" placement="bottom">
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Edit"
																					onClick={() => confirmFinance(item)}
																					className="px-1 text-primary pointer-event"
																					data-bs-original-title="Confirm Finance">
                                      <i className="bx bx-wallet font-size-18"></i>
                                    </span>
																	</ToolTipComponent>
																</li>
                                {/*<li className="list-inline-item">
                                  <span data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Info"
                                        className="px-1 text-info pointer-event">
                                    <i className="bx bx-info-circle font-size-18"></i>
                                  </span>
                                </li>*/}
															</ul>
														</td>
                          }
                        </tr>
                      )}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
      }
    </PageLayout>
  )
}

export default Signatories;
