interface Props {
  text?: string
  icon: any
  description: string
}

const EmptyTable = ({icon, text, description}: Props) => (
  <div className="row">
    <div className="col-12 text-center">
      <img src={icon} alt="No data found" className="mx-auto d-block img-fluid" height={200}/>
      <h3>{text}</h3>
      <p className="text-muted text-center mb-3">{description}</p>
    </div>
  </div>
);

export default EmptyTable;
