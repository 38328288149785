import {MouseEvent, useState} from "react";
import {NavigateFunction, NavLink, useNavigate} from "react-router-dom";
import moment from "moment";

import Gravatar from "../../../assets/images/pngs/gravatar.png";
import Alerts from "../../../components/modules/Alerts";
import PageLayout from "../../../components/layouts/PageLayout";
import Pagination from "./atom/Pagination";
import {MenuType} from "../../../utils/helpers/constants";
import {MessagingTypes} from "../../../mocks/Messaging";
import {useGetMessagingQuery} from "../../../store/slices/backoffice.slice";


const Messaging = () => {

  const navigate: NavigateFunction = useNavigate()
  const [size] = useState<number>(20);
  const [filter, setFilter] = useState<string>('');
  const [pageIndex, setPageIndex] = useState<number>(1);
  const {data: messages, isLoading} = useGetMessagingQuery(`?size=${size}&page=${pageIndex}${filter}`);

  const changeType = (event: MouseEvent<HTMLAnchorElement>, type: string): void => {
    event.preventDefault();
    let newFilter: string = type === 'APP' ? `&type=email` : type === 'SMS' ? `&type=sms` : `&type=email`
    setFilter("")
    // setFilter(newFilter)
  }

  const alertText: string = `Subsequent updates would improve sending email and in app notifications`

  return (
    <PageLayout active={MenuType.MESSAGING} title="Messaging - Emails and App Notifications" loading={isLoading}>
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-lg-12">
            <Alerts text="Note -" icon type="info" borderPlacement="top" dismissible={true} description={alertText}/>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="email-leftbar">
              <div className="card">
                <div className="card-body">
                  <button type="button" onClick={() => navigate('/messaging/create')} className="btn btn-danger w-100">
                    <i className="bx bx-mail-send me-1"></i> Compose Message
                  </button>

                  <div className="card p-0 overflow-hidden mt-4 shadow-none">
                    <div className="mail-list">
                      <NavLink onClick={(e: MouseEvent<HTMLAnchorElement>) => changeType(e, 'ALL')} to="/"
                               className="active bg-primary-subtle">
                        <div className="d-flex align-items-center">
                          <i className="mdi mdi-email-outline font-size-20 align-middle me-3"></i>
                          <div className="flex-grow-1">
                            <h5 className="font-size-14 mb-0">All Messages</h5>
                            <span className="text-muted font-size-13 text-truncate">Emails, SMS and InApp</span>
                          </div>
                        </div>
                      </NavLink>
                      {MessagingTypes.map((type: IMessagingType, index: number) => (
                        <NavLink key={index} onClick={(e: MouseEvent<HTMLAnchorElement>) => changeType(e, type.value)}
                                 to="/" className="border-bottom">
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-star-outline font-size-20 align-middle me-3"></i>
                            <div className="flex-grow-1">
                              <h5 className="font-size-14 mb-0">{type.name}</h5>
                              <span className="text-muted font-size-13 text-truncate">{type.description}</span>
                            </div>
                            <div className="flex-shrink-0">
                            </div>
                          </div>
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="email-rightbar mb-3">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="row mb-4">
                      <div className="col-xl-6 col-md-12">
                        <div className="pb-3 pb-xl-0">
                          <form className="email-search">
                            <div className="position-relative">
                              <input type="text" className="form-control bg-light" placeholder="Search..."/>
                              <span className="bx bx-search font-size-18"></span>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      {messages?.data.map((item: IMessage, index: number) =>
                        <div key={index} className="message-list mb-0 p-1">
                          <div className="list ">
                            <div className="col-mail col-mail-1">
                              <div className="d-flex title align-items-center">
                                <img src={Gravatar} className="avatar-sm rounded-circle" alt=""/>
                                <div className="flex-1 ms-2 ps-1">
                                  <h5 className="font-size-14 mb-0">{item.recipient_group}</h5>
                                  <div className="text-muted font-size-12">{item.recipient_group}</div>
                                </div>
                              </div>
                            </div>
                            <div className="col-mail col-mail-2">
                              <div className="subject text-body">{item.subject}</div>
                              <div className="date">
                                <i className="bx bx-link me-2 font-size-15 align-middle"></i>
                                {moment(item.created_at).format('ddd, hA')}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <Pagination pageIndex={pageIndex} size={size} count={messages?.count}
                                pageSize={messages?.data.length < size ? messages?.data.length : size}
                                setPageIndex={(index: number) => setPageIndex(index)}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Messaging;
