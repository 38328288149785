import CookieHelper from "./cookieHelper";
import {AppKeys} from "./constants";

export const SelectStyles = () => {
  let initTheme: string = CookieHelper.get(AppKeys.THEME) ?? 'light';
  let backgroundColor: string = initTheme === 'dark' ? '#202224' : '#FFFFFF';
  let disabledBackgroundColor: string = initTheme === 'dark' ? 'rgba(40,40,44,0.8)' : "#F0F2F5";
  let color: string = initTheme === 'dark' ? '#FFFFFF' : '#202224';
  let disabledColor: string = initTheme === 'dark' ? 'rgba(184,182,182,0.9)' : "#667185";
  let colorFocused: string = initTheme === 'dark' ? '#202224' : '#FFFFFF';
  let borderColor: string = initTheme === 'dark' ? '#282c2f' : '#eff0f2';
  let primaryColor: string = '#86a3e1'

  return {
    container: (provided: any, {isDisabled}: {isDisabled: boolean}) => ({
      ...provided, width: "100%",
      border: "none", outline: "none",
      color: isDisabled ? disabledColor : color,
      backgroundColor: isDisabled ? disabledBackgroundColor : backgroundColor,
    }),
    singleValue: (provided: any, {isDisabled}: {isDisabled: boolean}) => ({
      ...provided, overflow: "none",
      color: isDisabled ? disabledColor : color,
      backgroundColor: isDisabled ? disabledBackgroundColor : backgroundColor
    }),
    control: (provided: any, {isDisabled}: {isDisabled: boolean}) => ({
      ...provided,
      color: isDisabled ? disabledColor : color,
      backgroundColor: isDisabled ? disabledBackgroundColor : backgroundColor,
      padding: "0 10px 0 0",
      cursor: isDisabled ? "not-allowed" : "pointer",
      borderRadius: "0.75rem",
      border: `1px solid ${borderColor}`,
      ':active, :focus,  :hover': {
        ...provided[':active'],
        ...provided[':focus'],
        ...provided[':hover'],
        outline: "none",
        boxShadow: "none",
        border: "1px solid #8face3",
      }
    }),
    menu: (provided: any) => ({...provided, color, backgroundColor}),
    placeholder: (provided: any) => ({...provided, color, backgroundColor}),
    option: (provided: any, {isFocused}: {isFocused: boolean}) => ({
      ...provided,
      color: isFocused ? colorFocused : color,
      backgroundColor: isFocused ? primaryColor : backgroundColor,
      fontSize: "14px",
      cursor: "pointer",
      borderColor: 'red',
    })
  }
};
