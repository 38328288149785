export const LoanTypes: {label: string, value: ILoanType}[] = [
  {label: "Fuel", value: "FUEL"},
  {label: "Vehicle Repair", value: "VEHICLE_REPAIR"},
  {label: "Document Renewal", value: "DOCUMENT_RENEWAL"},
];

export const RepaymentDuration: IDynamicFields[] = [
  {label: "Days", value: "DAYS"},
  {label: "Weeks", value: "WEEKS"},
];
