import {useMemo, useState} from "react";
import {NavigateFunction, NavLink, useNavigate} from "react-router-dom";
import {CellContext, ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";

import PageLayout from "../../../components/layouts/PageLayout";
import Alerts from "../../../components/modules/Alerts";
import TableFilter from "../../../components/dataTable/TableFilter";
import DataTable from "../../../components/dataTable/DataTable";
import {MenuType} from "../../../utils/helpers/constants";
import {useGetStandardTripsQuery} from "../../../store/slices/backoffice.slice";
import {TripColumns} from "../../../components/dataTable/TripColumn";


const Standard = () => {

  const navigate: NavigateFunction = useNavigate()
  const [size] = useState<number>(20);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const {data: trips, isLoading, isFetching} = useGetStandardTripsQuery(`?size=${size}&page=${pageIndex}`);

  const column: ColumnHelper<ITrip> = createColumnHelper<ITrip>()
  const actionColumn: ColumnDef<ITrip, string> = column.accessor('id', {
    header: () => <span>Action</span>,
    enableSorting: false,
    cell: (info: CellContext<ITrip, string>) => {
      let reference: string = info.row.original.reference
      return (
        <span className="text-center pointer">
          <button className="btn btn-sm btn-primary"
                  onClick={() => navigate(`/trips/${reference}`, {state: reference})}>
            Trip Details
          </button>
        </span>
      )
    }
  })

  const alertText: string = `Subsequent updates adds actionable button to provide trip information, and searching for trips`
  const columns: ColumnDef<ITrip, string>[] = useMemo(() => [...TripColumns, actionColumn], []);

  const searchTrip = (searchParams: string) => {
    console.log(searchParams)
  }

  return (
    <PageLayout active={MenuType.TRIPS} title="House Moves and Relocation Trips" loading={isLoading}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6"/>
          <div className="col-md-6">
            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
              <div>
                <ul className="nav nav-pills">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/" data-bs-toggle="tooltip" data-bs-placement="top"
                             aria-label="List" data-bs-original-title="List"><i className="bx bx-list-ul"></i>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-lg-12">
            <Alerts text="Note -" icon type="info" borderPlacement="top" dismissible={true} description={alertText}/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header custom-card-header">
                <h1 className="card-title">House Moves and Relocation Trips</h1>
                <TableFilter searchFn={searchTrip}/>
              </div>
              <div className="card-body">
                <DataTable columns={columns} data={trips?.data ?? []} size={size} pageIndex={pageIndex} loadingState={isFetching}
                           count={trips?.count ?? 0} setPageIndex={(index: number) => setPageIndex(index)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Standard;
