interface Props {
  searchFn: (params: string) => void
}

const TableFilter = ({searchFn}: Props) => (
  <form className="table-search mb-2">
    <div className="position-relative">
      <input type="text" className="form-control bg-light" placeholder="Search..."
             onChange={event => searchFn(event.target.value.toUpperCase())}/>
      <span className="bx bx-search font-size-18"></span>
    </div>
  </form>
)

export default TableFilter
