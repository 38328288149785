import {Fragment, ReactNode} from "react";
import Dropdown from 'react-bootstrap/Dropdown';

interface ItemProps {
  text: string
  loading?: boolean
  isDivider?: boolean
  className?: string
  disabled?: boolean
  onclick: () => void
}

interface Props {
  buttonText: string
  buttonSize?: "sm" | "lg"
  variant: string
  children: ReactNode
}

export const DropDownItem = ({text, isDivider, loading, onclick, className, disabled}: ItemProps) => (
  <Fragment>
    <Dropdown.Item disabled={disabled} onClick={onclick} className={className ? className : ""}>
      {text} {loading && <i className="bx bx-loader-circle bx-spin"/>}
    </Dropdown.Item>
    {isDivider && <Dropdown.Divider/>}
  </Fragment>
)

export const DropDown = ({buttonText, variant, buttonSize, children}: Props) => (
  <Dropdown>
    <Dropdown.Toggle variant={variant} size={buttonSize}>{buttonText}</Dropdown.Toggle>
    <Dropdown.Menu>{children}</Dropdown.Menu>
  </Dropdown>
)
