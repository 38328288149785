import {ActionPromptEnum} from "../../interfaces/enums";

export const voucherStatusMap: IDynamicFields = {
  "INITIATED": {
    label: "Initiated",
    fill: "bg-success",
  },
  "PREPARED": {
    label: "Prepared",
    fill: "bg-success",
  },
  "APPROVED": {
    id: 3,
    label: "Approved",
    fill: "bg-success"
  },
  "RECEIVED": {
    id: 4,
    label: "Received",
    fill: "bg-success"
  },
  "PAID": {
    id: 5,
    label: "Paid",
    fill: "bg-success"
  },
  "REJECTED": {
    id: 3,
    label: "Rejected",
    fill: "bg-danger",
  },
}

export const LoanRequestMap: IDynamicFields = {
  "DISBURSED": {
    label: "Disbursed",
    fill: "bg-primary",
  },
  "PROCESSING": {
    label: "Processing",
    fill: "bg-warning",
  },
  "REPAYMENT": {
    label: "Processing",
    fill: "bg-info",
  },
  "REJECTED": {
    id: 3,
    label: "Rejected",
    fill: "bg-danger"
  },
  "COMPLETED": {
    id: 3,
    label: "Completed",
    fill: "bg-success"
  }
}

export const orgFlowMap: IDynamicFields = {
  "DEMAND_FLOW": {
    label: "Demand Services",
    fill: "bg-success",
  },
  "SUPPLY_FLOW": {
    label: "Supply Services",
    fill: "bg-success",
  },
  "DEMAND_SUPPLY_FLOW": {
    id: 3,
    label: "Demand and Supply Services",
    fill: "bg-success"
  }
}

export const ActionPromptMap = {
  [ActionPromptEnum.APPROVE_TOP_UP_REQUEST]: {
    id: 1,
    title: "Approve Top-Up Request",
    placeholder: "Type in the top-up request reference",
    message: "You are going to approve this top-up request with reference",
    actionText: "Yes, Approve Request",
    successMessage: "You’ve approved this quick top-up request",
  },
  [ActionPromptEnum.REJECT_TOP_UP_REQUEST]: {
    id: 2,
    title: "Deny Top-Up Request",
    placeholder: "Type in the top-up request reference",
    message: "You are going to reject this top-up request with reference",
    actionText: "Yes, Deny Request",
    successMessage: "You’ve successfully rejected this quick top-up request",
  },
  [ActionPromptEnum.REPAY_TOP_UP_REQUEST]: {
    id: 3,
    title: "Repay Top-Up Request",
    placeholder: "Type in the top-up request reference",
    message: "You are going to make a complete repayment of this quick top-up request with reference",
    actionText: "Yes, Repay Top-Up Request",
    successMessage: "You’ve successfully repaid this quick top-up request",
  },
}

