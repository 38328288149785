import React from "react";

export const emailValidator = (email: string): { validState: boolean, validEmail: string } => {
    const pattern = /\S+@\S+\.\S+/, validEmail = email.toLowerCase().replace(/ /g, "");
    const validState = pattern.test(validEmail);
    return { validState, validEmail }
};

export const phoneValidator = (phone: string) => {
    const pattern = /^\+\d{1,3}(\(\d+\))?\d{7,14}$/; //REGEX FOR PHONE WITH BRACKETS
    //REGEX FOR NUMBER WITHOUT BRACKETS /^\+\d{1,3}\d{7,14}$/;
    return pattern.test(phone);
}

export const removeSpecialCharacters = (text: string): string => {
    const pattern = /[^A-Za-z0-9]+/g;
    return text.replace(pattern, '');
};

export const validateIfFloat = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 101 || event.charCode === 45) event.preventDefault();
}

export const validateIfInteger = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 101 || (event.charCode === 46 || event.charCode === 45)) event.preventDefault();
}

