import {Fragment} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {Gallery, Item} from "react-photoswipe-gallery";

import {InputType} from "../../../../interfaces/enums";
import OutlineSVG from "../../../../assets/images/pngs/outline.png";
import {InputDynamicFields} from "../../../../interfaces/configs";
import {toTitleCase} from "../../../../utils/helpers/logicHelper";

type Props = {
  profile: Pick<IPartnerProfile, 'id' | 'user' | 'dynamic_fields'>,
  dynamicFields: InputDynamicFields[]
}

const SupportingDocument = ({profile, dynamicFields}: Props) => {
  const documentUpload = dynamicFields.filter(each => each.type === InputType.FILE);
  return (
    <Col xs={12}>
      <Row>
        {(documentUpload && documentUpload.length > 0) && documentUpload.map(item =>
          <Fragment key={item.key}>
            {(item.key in profile['dynamic_fields']) &&
							<Col md={6} xxl={4} className="mb-4">
								<Card className="overflow-hidden h-100">
									<Card.Header className="bg-primary py-2">
											<h6 className="text-white my-auto">{toTitleCase(item.key)}</h6>
									</Card.Header>
									<Card.Body style={{maxHeight: "400px"}}>
										<Gallery>
											<Item
												original={profile["dynamic_fields"][item.key] ?? OutlineSVG}
												thumbnail={profile["dynamic_fields"][item.key] ?? OutlineSVG}
												id={toTitleCase(item.key)}
												width="1024" height="768">
                        {({ref, open}) => (
                          <img ref={ref} onClick={open} src={profile["dynamic_fields"][item.key] ?? OutlineSVG}
                               alt={toTitleCase(item.key)} className="h-100 w-100"
                          />
                        )}
											</Item>
										</Gallery>
									</Card.Body>
								</Card>
							</Col>
            }
          </Fragment>
        )}
      </Row>
    </Col>
  )
}
export default SupportingDocument;
