import {useMemo, useState} from "react";
import {ColumnDef} from "@tanstack/react-table";

import PageLayout from "../../../components/layouts/PageLayout";
import TableFilter from "../../../components/dataTable/TableFilter";
import DataTable from "../../../components/dataTable/DataTable";
import {MenuType} from "../../../utils/helpers/constants";
import {useGetWebsiteLeadsQuery} from "../../../store/slices/backoffice.slice";
import { WebsiteLeadsColumn } from "../../../components/dataTable/WebsiteLeadsColumn";


const WebsiteLeads = () => {
  const [size] = useState<number>(20);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const { data: leads, isLoading, isFetching } = useGetWebsiteLeadsQuery(`?size=${size}&page=${pageIndex}`);

  const columns: ColumnDef<IWebsiteLead, string>[] = useMemo(() => [...WebsiteLeadsColumn], []);

  const searchLeads = (searchParams: string): void => {
    console.log(searchParams)
  }


  return (
    <PageLayout active={MenuType.WEBSITELEADS} title="Website Leads" loading={isLoading}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header custom-card-header">
                <div>
                  <h4 className="card-title">Website Leads</h4>
                  <p className="card-title-desc">List of website generated leads</p>
                </div>
                <TableFilter searchFn={searchLeads}/>
              </div>
              <div className="card-body">
              <DataTable columns={columns} data={isLoading ? [] : leads.data} size={size} pageIndex={pageIndex} loadingState={isFetching}
                           count={isLoading ? 0 : leads.count} setPageIndex={(index: number) => setPageIndex(index)}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default WebsiteLeads;
