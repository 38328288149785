import {Fragment, ReactElement, RefObject, useEffect, useRef, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import toast from "react-hot-toast";

import {useUpdateContentMutation} from "../../../store/slices/backoffice.slice";

interface Props {
  topic: ITopics
  initSidebarTree: ISidebarTree[]
}

interface ItemProps {
  position: number
  item: ISidebarTree | IBreadcrumbs
  moveCard: (stack: number, position: number, slug: string, labelled: boolean) => void
  shift: boolean
}

interface DragItem {
  shift: boolean
  slug: string
  position: number
}

const Item = ({item, shift, position, moveCard}: ItemProps) => {
  const navigate: NavigateFunction = useNavigate(), ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
  const viewContent = (content: ISidebarTree | IBreadcrumbs): void => navigate(`/documentation/content/${content.href}`)

  const [{isDragging}, drag] = useDrag({
    type: "CARD",
    item: (): DragItem => ({slug: item.slug, position: item.position, shift}),
    collect: (monitor: any): { isDragging: any } => ({isDragging: monitor.isDragging()}),
  })

  const [, drop] = useDrop({
    accept: "CARD",
    hover(item: DragItem): void {
      const oldPosition: number = item.position
      if (!ref.current) return;
      if (oldPosition === position) return;
      moveCard(oldPosition, position, item.slug, item.shift)
    }
  });

  drag(drop(ref))
  const opacity: number = isDragging ? 0 : 1;
  return (
    <div ref={ref} className={`event-card ${shift ? 'shift' : ''} bg-success pointer-event`} style={{opacity}}>
      <span onClick={() => viewContent(item)} className="underline-hover">
        <i className="bx bxs-circle font-size-11 me-2"></i>{item.title}
      </span>
    </div>
  )
}

const ContentList = ({initSidebarTree, topic}: Props) => {

  const [sidebarTree, setTree] = useState<ISidebarTree[]>(initSidebarTree);
  const [updateContentMutation] = useUpdateContentMutation();

  useEffect(() => {
    setTree(initSidebarTree)
  }, [initSidebarTree]);

  const moveCard = async (oldPosition: number, position: number, slug: string, labelled: boolean): Promise<void> => {
    let draggedItem, newSidebarTree: ISidebarTree[] = [...sidebarTree];
    if (labelled) {
      let content: ISidebarTree | undefined = newSidebarTree.find((item: ISidebarTree): boolean => item.position === oldPosition)
      if (content) {
        let newDraggedItem: IBreadcrumbs | undefined = content.tree.find((item: IBreadcrumbs): boolean => item.slug === slug);
        if (newDraggedItem) newDraggedItem = {...newDraggedItem, position: position}
        if (newDraggedItem?.stack === content.stack) draggedItem = content
      }
    } else draggedItem = newSidebarTree.find((item: ISidebarTree): boolean => item.slug === slug);



    if (0) await updateStack(slug, position);
    // setTree(update(sidebarTree, {$splice: [[oldPosition, 1], [position, 0, draggedItem]]}));

  }

  const updateStack = async (slug: string, position: number): Promise<void> => {
    try {
      const body: IDynamicFields = {stack: position}
      let result = await updateContentMutation({filter: `update-stack/${topic.topic}/${slug}`, body}).unwrap()
      toast.success(result.message)
    } catch (error: any) {
      toast.error(error.data.message)
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <p className="text-muted">Drag and drop the contents to arrange the items on the menu</p>
      {sidebarTree.map((item: ISidebarTree): ReactElement => (
        !item.label ? <Item key={item.slug} position={item.position} item={item} moveCard={moveCard} shift={false}/> :
          <Fragment key={item.slug}>
            <div className="event-card bg-success pointer-event">
              <i className="bx bxs-caret-down-circle font-size-11 me-2"></i>{item.title}
            </div>
            {item.tree.map((tree: IBreadcrumbs) => (
              <Item key={tree.slug} position={tree.position} item={tree} moveCard={moveCard} shift={true}/>
            ))}
          </Fragment>
      ))}
    </DndProvider>
  )
}

export default ContentList;
