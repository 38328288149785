import {useState} from "react";
import moment from "moment";
import Offcanvas from 'react-bootstrap/Offcanvas';

interface Props {
  updates: ILeadUpdates[]
  onchange: () => void
}

const LeadStatusCanvas = ({updates, onchange}: Props) => {

  const [show, setShow] = useState<boolean>(true);
  const handleClose = () => {
    setShow(false);
    onchange();
  }

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Lead Acquisition Updates</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="dash-timeline">
          <ul className="task-list mb-0">
            {updates.map((item: ILeadUpdates, index: number) =>
              <li key={index} className="mt-0">
                <i className="task-icon"/>
                <span>{item.message}</span>
                <p className="mt-1 text-muted font-size-12">{item.status} | {moment(item.time).format('LLL')}</p>
              </li>
            )}

          </ul>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default LeadStatusCanvas;
