import moment from "moment/moment";
import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {formatAmountToString, toTitleCase} from "../../utils/helpers/logicHelper";
import {LoanRequestMap} from "../../utils/helpers/mappers";

const column: ColumnHelper<ILoanRequest> = createColumnHelper<ILoanRequest>()

export const LoanRequestColumn: ColumnDef<ILoanRequest, any>[] = [
  column.accessor('reference', {
    header: () => <span>Ref</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('driver.user.first_name', {
    header: () => <span>Requester</span>,
    cell: info => <span>{toTitleCase(info.getValue())} {toTitleCase(info.row.original?.driver?.user?.last_name)}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('driver.user.email', {
    header: () => <span>Requester Email</span>,
    cell: info => <span>{info.getValue().toLowerCase()}</span>,
    footer: info => info.column.id,
  }),
  column.accessor(`loan_config.loan_type`, {
    header: () => <span>Type</span>,
    cell: (info) => <span>{toTitleCase(info.getValue())}</span>,
    footer: (info) => info.column.id,
  }),
  column.accessor('amount', {
    header: () => <span>Top-Up Amount</span>,
    cell: info => <span>{formatAmountToString(info.getValue())}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('repayment_amount', {
    header: () => <span>Repayment Amount</span>,
    cell: info => <span>{formatAmountToString(info.getValue())}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('due_date', {
    header: () => <span>Repayment Due Date</span>,
    cell: info => <span>{moment(info.getValue()).format('lll')}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('status', {
    header: () => <span>Status</span>,
    cell: info => <span className={`badge rounded-pill ${LoanRequestMap[info.getValue()].fill}`}>{LoanRequestMap[info.getValue()].label}</span>,
    footer: info => info.column.id,
  })
];
