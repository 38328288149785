import {ChangeEvent, useState} from "react";
import {Location, Params, useLocation, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {Gallery, Item} from "react-photoswipe-gallery";
import toast from "react-hot-toast";
import moment from "moment";

import OutlineSVG from "../../../assets/images/pngs/outline.png";
import PdfSVG from "../../../assets/images/pngs/pdf.png";
import PageLayout from "../../../components/layouts/PageLayout";
import EmptyTable from "../../../components/dataTable/EmptyTable";
import ToolTipComponent from "../../../components/modules/ToolTipComponent";
import UploadVoucherReceiptModal from "../../../components/modals/UploadVoucherReceiptModal";
import {formatAmountToString, toTitleCase} from "../../../utils/helpers/logicHelper";
import {MenuType} from "../../../utils/helpers/constants";
import {useGetVouchersQuery, useSendVoucherMutation} from "../../../store/slices/backoffice.slice";


const VoucherInfo = () => {
  const {reference}: Readonly<Params> = useParams(), location: Location<any> = useLocation();
  const userProfile: IProfile = useSelector((state: IStoreState) => state.auth.userProfile)
  const {data: voucher, isLoading, isSuccess} = useGetVouchersQuery(`${reference}`)
  const [updateVoucher, {isLoading: sendLoading}] = useSendVoucherMutation()
  const [uploadReceiptRender, setUploadReceiptRender] = useState<boolean>(false)

  const handleSend = async (status: string): Promise<void> => {
    try {
      let filter = voucher?.data?.id, body = {status}
      await updateVoucher({filter, body})
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  const viewPDF = (item: IVoucherReceipts): void => {
    if (item.type === "application/pdf" && item.image) window.open(item.image)
  }

  const addUploadReceiptModal = uploadReceiptRender &&
    <UploadVoucherReceiptModal voucher={voucher.data} onchange={() => setUploadReceiptRender(false)}/>

  return (
    <PageLayout active={MenuType.VOUCHER} loading={isLoading} backEnabled={location.state}
                title={`Voucher - ${reference}`}>
      {addUploadReceiptModal}
      {isSuccess &&
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="invoice-title">
                    <h4 className="float-end font-size-15">Voucher {reference}
                      <span
                        className={`badge ${voucher?.data?.status === "REJECTED" ? "bg-danger" : "bg-success"} font-size-12 ms-2`}>
                      {voucher?.data?.status}
                    </span>
                    </h4>
                    <div className="text-muted">
                      <p className="mb-1">Prepared By:</p>
                      <p className="mb-1">
                        {toTitleCase(voucher?.data?.prepared_by?.first_name)} {toTitleCase(voucher?.data?.prepared_by?.last_name)}
                      </p>
                      <p className="mb-1">{voucher?.data?.prepared_by?.email}</p>
                    </div>
                  </div>

                  <hr className="my-4"/>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="text-muted">
                        <h5 className="font-size-16 mb-3">Payment To:</h5>
                        <h5 className="font-size-15 mb-2">{voucher?.data?.payee}</h5>
                        <p className="mb-1">{voucher?.data?.payee_bank}</p>
                        <p className="mb-1">{voucher?.data?.payee_bank_account}</p>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="text-muted text-sm-end">
                        <div className="mt-4">
                          <h5 className="font-size-15 mb-1">Invoice Date:</h5>
                          <p>{moment(voucher?.data?.created_at).format('LLL')}</p>
                        </div>
                        <div className="mt-4">
                          <h5 className="font-size-15 mb-1">Approval Date:</h5>
                          <p>{voucher?.data?.approved_by_status ? `${moment(voucher?.data?.approved_at).format('LLL')}` : 'NOT APPROVED'}</p>
                        </div>
                        <div className="mt-4">
                          <h5 className="font-size-15 mb-1">Processed Date:</h5>
                          <p>{voucher?.data?.received_by_status ? `${moment(voucher?.data?.received_at).format('LLL')}` : 'NOT PROCESSED'}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="py-2">
                    <h5 className="font-size-15">Voucher Summary</h5>

                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-centered mb-0">
                        <thead>
                        <tr>
                          <th className="fw-bold">No.</th>
                          <th className="fw-bold">Details of Payment</th>
                          <th className="fw-bold">Amount</th>
                          <th className="text-end fw-bold">Total</th>
                        </tr>
                        </thead>

                        <tbody>
                        {voucher?.data?.description.map((item: any, index: number) =>
                          <tr key={index}>
                            <th scope="row">{index + 1}.</th>
                            <td><h5 className="text-truncate font-size-14 mb-1" dangerouslySetInnerHTML={{__html: item.details}}/></td>
                            <td>{item.amount}</td>
                            <td className="text-end">NGN {item.amount}</td>
                          </tr>
                        )}
                        <tr>
                          <th scope="row" colSpan={3} className="border-0 text-end fw-bold">Total</th>
                          <td className="border-0 text-end">
                            <h4 className="m-0 fw-semibold">NGN{formatAmountToString(voucher?.data?.total)}</h4>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-print-none mt-4">
                      <div className="float-end">
                        <ToolTipComponent text="Upload Receipt" placement="left">
                          <button onClick={() => setUploadReceiptRender(true)} className="btn btn-info me-1">
                            <i className="bx bx-cloud-upload"></i>
                          </button>
                        </ToolTipComponent>

                        {/*<button className="btn btn-secondary me-1"><i className="bx bx-printer"></i></button>*/}
                        {voucher?.data?.status === "INITIATED" && userProfile.id === voucher?.data?.prepared_by['user_id'] &&
                          voucher?.data?.prepared_by_status &&
                          <button className="btn btn-primary w-md mx-1" onClick={() => handleSend("PREPARED")}>
                            Forward {sendLoading ? <i className="bx bx-loader-circle bx-spin"/> :
                            <i className="bx bx-caret-right"/>}
                          </button>
                        }
                        {voucher?.data?.status !== "INITIATED" && userProfile.id === voucher?.data?.approved_by['user_id'] &&
                          voucher?.data?.prepared_by_status && !voucher?.data?.approved_by_status &&
                          <button className="btn btn-primary w-md mx-1" onClick={() => handleSend("APPROVED")}>
                            Approve {sendLoading ? <i className="bx bx-loader-circle bx-spin"/> :
                            <i className="bx bx-check-circle"/>}
                          </button>
                        }
                        {userProfile.id === voucher?.data?.received_by['user_id'] && voucher?.data?.approved_by_status &&
                          !voucher?.data?.received_by_status && voucher?.data?.status !== "REJECTED" &&
                          <button className="btn btn-primary w-md mx-1" onClick={() => handleSend("RECEIVED")}>
                            Receive {sendLoading ? <i className="bx bx-loader-circle bx-spin"/> :
                            <i className="bx bx-down-arrow"/>}
                          </button>
                        }
                        {voucher?.data?.status !== "PAID" && voucher?.data?.status !== "REJECTED" &&
                          userProfile.id === voucher?.data?.received_by['user_id'] && voucher?.data?.received_by_status &&
                          <button className="btn btn-primary w-md mx-1" onClick={() => handleSend("PAID")}>
                            Pay {sendLoading ? <i className="bx bx-loader-circle bx-spin"/> :
                            <i className="bx bx-wallet"/>}
                          </button>
                        }
                        {voucher?.data?.status !== "INITIATED" && voucher?.data?.status !== "PAID" &&
                          voucher?.data?.status !== "REJECTED" &&
                          <button className="btn btn-danger w-md mx-1" onClick={() => handleSend("REJECTED")}>
                            Reject {sendLoading ? <i className="bx bx-loader-circle bx-spin"/> :
                            <i className="bx bx-block"/>}
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header"><h4 className="card-title">Receipts</h4></div>
                <div className="card-body">
                  {voucher.data.receipts.length > 0 ?
                    <div className="row">
                      {voucher.data.receipts.map((item: IVoucherReceipts, index: number) =>
                        <div key={index} className="col-xl-2 col-md-4 col-6">
                          <div className="mt-4">
                            <div className="img-fluid">
                              {item.type && item.type === "application/pdf" ?
                                <img src={PdfSVG} alt="" onClick={() => viewPDF(item)}
                                     className="img-thumbnail rounded pointer-event" width="200"/> :
                                <Gallery>
                                  <Item original={item.image ?? OutlineSVG} thumbnail={item.image ?? OutlineSVG}
                                        width="1024" height="1024">
                                    {({ref, open}) => (
                                      <img ref={ref} onClick={open} src={item.image ?? OutlineSVG} alt="" width="200"
                                           className="img-thumbnail rounded pointer-event"
                                           onError={(e: ChangeEvent<HTMLImageElement>) => (e.target as HTMLImageElement).src = PdfSVG}
                                      />
                                    )}
                                  </Item>
                                </Gallery>
                              }
                            </div>
                          </div>
                        </div>
                      )}
                    </div> :
                    <EmptyTable icon={OutlineSVG} description="No receipts attached to this voucher"/>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </PageLayout>
  )
}

export default VoucherInfo;
