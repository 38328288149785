import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {formatAmountToString, toTitleCase} from "../../utils/helpers/logicHelper";

const column: ColumnHelper<ILoanConfig> = createColumnHelper<ILoanConfig>()

export const LoanConfigurationColumn: ColumnDef<ILoanConfig, any>[] = [
  column.accessor('loan_type', {
    header: () => <span>Type</span>,
    cell: info => <span>{toTitleCase(info.getValue())}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('loan_cap', {
    header: () => <span>Maximum Amount Payable</span>,
    cell: info => <span>{formatAmountToString(info.getValue())}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('interest_rate', {
    header: () => <span>Transaction Rate (%)</span>,
    cell: info => <span>{formatAmountToString(info.getValue())}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('repayment_duration', {
    header: () => <span>Repayment Duration</span>,
    cell: info => <span>{formatAmountToString(info.getValue())}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('repayment_unit', {
    header: () => <span>Repayment Unit</span>,
    cell: info => <span>{toTitleCase(info.getValue())}</span>,
    footer: info => info.column.id,
  })
];
