import {useState} from "react";
import {Container} from "react-bootstrap";
import {MenuType} from "../../../utils/helpers/constants";
import PageLayout from "../../../components/layouts/PageLayout";
import LoanConfigurationList from "./misc/LoanConfigurationList";
import {useGetLoanConfigurationQuery} from "../../../store/slices/backoffice.slice";
import LoanConfigurationModal from "../../../components/modals/LoanConfigurationModal";

const LoanConfiguration = () => {
  const [addConfigurationRender, setAddConfigurationRender] = useState(false);
  const {data: configs, isLoading} = useGetLoanConfigurationQuery('');

  const addLoanConfigurationModal = addConfigurationRender &&
		<LoanConfigurationModal onchange={() => setAddConfigurationRender(false)}/>

  return (
    <PageLayout active={MenuType.SUPPLY} title="Top-Up Configuration" loading={isLoading} backEnabled>
      {addLoanConfigurationModal}
      <Container fluid>
        <div className="d-flex justify-content-end gap-2 mb-3">
          <button className="btn btn-primary pointer-event" onClick={() => setAddConfigurationRender(true)}>
            <i className="bx bx-plus me-1"></i> Add Top-Up Configuration
          </button>
        </div>

        <LoanConfigurationList configs={configs?.data ?? []} count={configs?.count ?? 0} loading={isLoading}/>
      </Container>
    </PageLayout>
  )
}

export default LoanConfiguration;
