export enum ActionPromptEnum {
  APPROVE_TOP_UP_REQUEST = "APPROVE_TOP_UP_REQUEST",
  REJECT_TOP_UP_REQUEST = "REJECT_TOP_UP_REQUEST",
  REPAY_TOP_UP_REQUEST = "REPAY_TOP_UP_REQUEST",
}

export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  OPTIONS = 'options',
  RADIO = 'radio',
  BOOLEAN = 'boolean',
  FILE = 'file',
  NUMBER = 'number',
  DATE = 'date'
}
