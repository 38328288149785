import {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Card, Col, Container, Row} from "react-bootstrap";
import PageLayout from "../../../components/layouts/PageLayout";
import DataTable from "../../../components/dataTable/DataTable";
import {MenuType, SubMenuType} from "../../../utils/helpers/constants";
import {DropDown, DropDownItem} from "../../../components/modules/DropDown";
import LoanStatusCanvas from "../../../components/offcanvas/LoanStatusCanvas";
import {useGetLoanRequestQuery} from "../../../store/slices/backoffice.slice";
import {LoanRequestColumn} from "../../../components/dataTable/LoanRequestColumn";
import {CellContext, ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";

const LoanRequests = () => {
  const navigate = useNavigate();
  const [size] = useState(20);
  const [pageIndex, setPageIndex] = useState(1);
  const [loanCanvasRender, setLoanCanvasRender] = useState(false);
  const [request, setRequest] = useState<ILoanRequest | null>(null);

  const {data: requests, isLoading} = useGetLoanRequestQuery('');

  const column: ColumnHelper<ILoanRequest> = createColumnHelper<ILoanRequest>();
  const actionColumn: ColumnDef<ILoanRequest, string> = (column.accessor('id', {
    header: () => <span>Action</span>,
    enableSorting: false,
    cell: (info: CellContext<ILoanRequest, string>) => {
      let request: ILoanRequest = info.row.original;
      return (
        <div className="btn-group">
          <DropDown buttonText="Actions" variant="primary" buttonSize="sm">
            <DropDownItem text="View Info"
                          onclick={() => navigate(`/${MenuType.SUPPLY}/${SubMenuType.LOAN}/requests/${request.reference}`, {state: request})}/>
            <DropDownItem text="Status History" onclick={() => openLoanCanvasStatusRender(request)}/>
          </DropDown>
        </div>
      )
    }
  }))
  const columns: ColumnDef<ILoanRequest, string>[] = useMemo(() => [...LoanRequestColumn, actionColumn], []);

  const openLoanCanvasStatusRender = (request: ILoanRequest) => {
    setRequest(request);
    setLoanCanvasRender(true);
  }
  const loanStatusCanvas = loanCanvasRender &&
		<LoanStatusCanvas updates={request?.journey} onchange={() => setLoanCanvasRender(false)}/>

  return (
    <PageLayout active={MenuType.SUPPLY} title="Top-Up Requests" loading={isLoading}>
      {loanStatusCanvas}
      <Container fluid>
        <div className="d-flex justify-content-end gap-2 mb-3">
          <button className="btn btn-primary pointer-event"
                  onClick={() => navigate(`/${MenuType.SUPPLY}/${SubMenuType.LOAN}/configuration`)}>
            <i className="bx bx-list-ol me-1"></i> Quick Top-Up Configuration
          </button>
        </div>

        <Row>
          <Col xs={12}>
            <Card className="card-body">
              <h5 className="card-title">Quick Top-Up Request</h5>
              <DataTable columns={columns} data={requests?.data ?? []} pageIndex={pageIndex} size={size}
                         count={requests?.count ?? 0} loadingState={isLoading}
                         setPageIndex={(index: number) => setPageIndex(index)}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  )
}

export default LoanRequests;
