import {ReactElement, useState} from "react";

interface Props {
  size: number
  count: number
  pageIndex: number
  pageSize: number
  setPageIndex: (index: number) => void
}

const Pagination = ({size, count, pageIndex, pageSize, setPageIndex}: Props) => {

  const renderBreakPoint: number = 5;
  const initLastIndex: number = Math.ceil(count / size) > renderBreakPoint ? renderBreakPoint : Math.ceil(count / size);
  const [extraIndex, setExtraIndex] = useState<number>(0)
  const [extraRender, setExtraRender] = useState<boolean>(false)
  const canPrevious: boolean = pageIndex > 1, canNext: boolean = Math.ceil(count / size) !== pageIndex
  const prePageCount: number = pageIndex === 1 ? pageIndex : size * (pageIndex - 1) + 1;
  const postPageCount: number | null = pageSize === 1 ? null : prePageCount + (pageSize - 1);

  const goToPageIndex = (index: number): void => {
    localStorage.setItem('pageIndex', index.toString());
    if (pageIndex !== index) setPageIndex(index);
  }

  const reduceAdvance = (): void => {
    let lastPage = localStorage.getItem('lastPageIndex');
    let newLastPage: number = Number(lastPage) - renderBreakPoint;
    let lastPageIndex: number = newLastPage < renderBreakPoint ? renderBreakPoint : newLastPage
    setPageIndex(lastPageIndex)
    setExtraIndex(lastPageIndex)
    setExtraRender(true)
  }

  const setAdvance = (): void => {
    let lastPage = localStorage.getItem('lastPageIndex');
    let newLastPage: number = Number(lastPage) + renderBreakPoint;
    let lastPageIndex: number = newLastPage > Math.ceil(count / size) ? Math.ceil(count / size) : newLastPage
    setPageIndex(Number(lastPage))
    setExtraIndex(lastPageIndex)
    setExtraRender(true)
  }

  const createPageRenderButtons = (lastPage: number) => {
    let preRender: ReactElement[] = [];
    let firstPage: number = lastPage - renderBreakPoint > 1 ? lastPage - renderBreakPoint : 1;
    for (let i: number = firstPage; i <= lastPage; i++) {
      preRender.push(
        <button key={i} onClick={() => goToPageIndex(i)} className={pageIndex === i ? `pag-currentPage` : ''}
                aria-label={`Page ${i}`}>{i}</button>
      )
    }
    localStorage.setItem('lastPageIndex', preRender.length.toString());
    return preRender
  }

  return (
    <>
      <div className="pag-footer">
        <div className="pag-pagination">
          <div role="status" className="pag-summary">
            Showing <b>{prePageCount} {postPageCount ? '-' : ''} {postPageCount}</b> of <b>{count}</b> results
          </div>

          <div className="pag-pages">
            {(extraRender) &&
              <button title="Init" onClick={() => reduceAdvance()} className="item">{`<<<`}</button>
            }
            <button title="Previous" disabled={!canPrevious} onClick={() => goToPageIndex(pageIndex - 1)}
                    className="item">Previous
            </button>
            {extraRender ? createPageRenderButtons(extraIndex) : createPageRenderButtons(initLastIndex)}
            <button title="Next" disabled={!canNext} onClick={() => goToPageIndex(pageIndex + 1)}
                    className="item">Next
            </button>
            <button title="Advance" disabled={!canNext} onClick={() => setAdvance()} className="item">{`>>>`}</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
