import {useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";

import Gravatar from "../../../assets/images/pngs/gravatar.png"
import PageLayout from "../../../components/layouts/PageLayout";
import CreateStaffModal from "../../../components/modals/CreateStaffModal";
import ToolTipComponent from "../../../components/modules/ToolTipComponent";
import {toTitleCase} from "../../../utils/helpers/logicHelper";
import ProfileService from "../../../utils/services/profile.service";
import {Loading, MenuType} from "../../../utils/helpers/constants";
import {setPermissionMatrix} from "../../../store/slices/auth.slice";
import {MockPermission, MockProfile} from "../../../mocks/MockProfile";
import {useBanStaffMutation, useGetSignatoriesQuery, useGetStaffQuery} from "../../../store/slices/backoffice.slice";


const Staff = () => {
  const navigate: NavigateFunction = useNavigate(), dispatch = useDispatch()
  const profile: IProfile = useSelector((state: IStoreState) => state.auth.userProfile)
  const loadingMatrices: boolean = useSelector((state: IStoreState) => state.auth.loadingMatrices)
  const permissionMatrices: IPermissions[] = useSelector((state: IStoreState) => state.auth.permissionMatrices)
  const {data: staff, isLoading, isSuccess} = useGetStaffQuery("")
  const {data: signatories, isLoading: loadingSignatories} = useGetSignatoriesQuery('');
  const [banStaffMutation] = useBanStaffMutation();

  const [addStaffRender, setAddStaffRender] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [user, setUser] = useState<IProfile>(MockProfile)
  const [permission, setUserPermission] = useState<IProfilePermissions>(MockPermission)


  useEffect(() => {
    if (!loadingMatrices) getPermissions().catch(err => console.log(err))
  }, []);

  const getPermissions = async (): Promise<void> => {
    const {data: responseData} = await ProfileService.getPermissionMatrices('');
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else dispatch(setPermissionMatrix(responseData.data))
  }

  const selectUser = async (newUser: IProfile): Promise<void> => {
    setLoading(true);
    const {data: responseData} = await ProfileService.getPermissionMatrices(newUser.id);
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      setUser(newUser)
      setUserPermission(responseData.data)
    }
    setLoading(false);
  }

  const togglePermission = async (matrix: IPermissions): Promise<void> => {
    const payload: IPermissionUpdateRequest = {permissionId: matrix.id, value: !permission[matrix.id]};
    const {data: responseData} = await ProfileService.toggleMatrix(user.id, payload);
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else toast.success(responseData.message);
  }

  const banStaff = async (user: IProfile): Promise<void> => {
    try {
      let filter: string = user.id, body: IBanStaffRequest = {status: !user.is_active}
      await banStaffMutation({filter, body}).unwrap();
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  const addStaffModal = addStaffRender && <CreateStaffModal onchange={() => setAddStaffRender(false)}/>

  return (
    <PageLayout active={MenuType.STAFF} title="Staff and Permissions" loading={isLoading || loadingSignatories}>
      {addStaffModal}
      {isSuccess &&
				<div className="container-fluid">
					<div className="d-flex flex-wrap justify-content-end gap-2 mb-3">
						<button className="btn btn-info" onClick={() => navigate('/signatories')}>
							<i className="bx bx-fingerprint me-1"></i> Voucher Signatories
						</button>
            {profile.is_superadmin &&
							<button className="btn btn-primary" onClick={() => setAddStaffRender(true)}>
								<i className="bx bx-plus me-1"></i> Add Staff
							</button>
            }
					</div>
					<div className="row">
						<div className="col-lg-8">
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-nowrap align-middle">
											<thead className="table-light">
											<tr>
												<th scope="col">Name</th>
												<th scope="col">Email</th>
												<th scope="col">Status</th>
                        {profile?.permissions?.edit_permission && <th scope="col">Action</th>}
											</tr>
											</thead>
											<tbody>
                      {staff.data.map((item: IProfile, index: number) =>
                        <tr key={index}>
                          <td width="40%">
                            <img src={item.picture ?? Gravatar} alt="profile_photo"
                                 className="avatar rounded-circle img-thumbnail me-2"/>
                            <span>{toTitleCase(item.first_name)} {toTitleCase(item.last_name)}</span>
                          </td>
                          <td width="30%">{item.email}</td>
                          <td width="20%">
                            <span className={`badge rounded-pill mx-1 ${item.is_active ? 'bg-success' : 'bg-danger'}`}>
                              {item.is_active ? 'Active' : 'Inactive'}
                            </span>
                            <span className={"badge rounded-pill bg-info"}>
                              {signatories?.data.find((each: ISignatory): boolean => each.user_id === item.id) ? 'Signatory' : ''}
                            </span>
                          </td>
                          {profile?.permissions?.edit_permission &&
														<td width="10%">
															<ul className="list-inline mb-0">
																<li className="list-inline-item">
																	<ToolTipComponent text="Edit Permissions" placement="bottom">
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Edit"
																					onClick={() => selectUser(item)}
																					className="px-1 text-primary pointer-event" aria-label="Edit">
                                      <i className="bx bx-pencil font-size-18"></i>
                                    </span>
																	</ToolTipComponent>
																</li>
																<li className="list-inline-item">
																	<ToolTipComponent text={`${item.is_active ? 'Disable' : 'Enable'} Profile`}
																										placement="bottom">
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Ban"
																					onClick={() => banStaff(item)} className="px-1 text-danger pointer-event">
                                      {item.is_active ? <i className="bx bx-block"></i> :
                                        <i className="bx bx-check text-success font-size-18"></i>}
                                  </span>
																	</ToolTipComponent>
																</li>
															</ul>
														</td>
                          }
                        </tr>
                      )}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
            {profile?.permissions?.edit_permission &&
							<div className="col-lg-4">
								<div className="card">
									<div className="card-header">
										<h4 className="card-title mb-0">User Permissions</h4>
										<p className="card-title-desc">
                      {user.id ? `Permission matrix for ${user.first_name} ${user.last_name}` : 'Select a user to view permission matrix'}
										</p>
									</div>
									<div className="card-body">
                    {!loading && user.id && permission && permissionMatrices.map((matrix: IPermissions, index: number) =>
                      <div key={index} className="form-check mb-3">
                        <input type="checkbox" className="form-check-input" name={matrix.name} id={matrix.id}
                               defaultChecked={permission[matrix.id]} onChange={() => togglePermission(matrix)}/>
                        <label className="form-check-label" htmlFor="formCheck1">{matrix.name}</label>
                      </div>
                    )}
									</div>
								</div>
							</div>
            }
					</div>
				</div>
      }
    </PageLayout>
  )
}

export default Staff;
