import {useMemo, useState} from "react";
import toast from "react-hot-toast";
import {CellContext, ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";

import PageLayout from "../../../components/layouts/PageLayout";
import TableFilter from "../../../components/dataTable/TableFilter";
import DataTable from "../../../components/dataTable/DataTable";
import PostMessageModal from "../../../components/modals/PostMessageModal";
import UpdateLeadModal from "../../../components/modals/UpdateLeadModal";
import LeadStatusCanvas from "../../../components/offcanvas/LeadStatusCanvas";
import {DropDown, DropDownItem} from "../../../components/modules/DropDown";
import {MenuType} from "../../../utils/helpers/constants";
import {useGetDriverLeadsQuery, useOnboardDriverLeadMutation} from "../../../store/slices/backoffice.slice";
import {DriverLeadsColumn} from "../../../components/dataTable/DriverLeadsColumn";
import {MockDriverLead} from "../../../mocks/MockLeads";

const DriverLeads = () => {
  const [size] = useState<number>(20);
  const [postMessageRender, setPostMessageRender] = useState<boolean>(false);
  const [leadCanvasRender, setLeadCanvasRender] = useState<boolean>(false);
  const [updateLeadInfoRender, setUpdateLeadInfoRender] = useState<boolean>(false);
  const [lead, setLead] = useState<IDriverLead>(MockDriverLead);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const { data: leads, isLoading, isFetching } = useGetDriverLeadsQuery(`?size=${size}&page=${pageIndex}`);
  const column: ColumnHelper<IDriverLead> = createColumnHelper<IDriverLead>()

  const [onboardLeadMutation, {isLoading: loadingOnboarding}] = useOnboardDriverLeadMutation();

  const actionColumn: ColumnDef<IDriverLead, string> = column.accessor('id', {
    header: () => <span>Action</span>,
    enableSorting: false,
    cell: (info: CellContext<IDriverLead, string>) => (
      <div className="btn-group">
        <DropDown buttonText="Actions" variant="primary" buttonSize="sm">
          <DropDownItem text="View Driver Status" onclick={() => viewLeadStatus(info.row.original)}/>
          <DropDownItem text="Update Lead Info" onclick={() => updateLead(info.row.original)}/>
          <DropDownItem text="Add Report" onclick={() => postReport(info.row.original)}/>
          {info.row.original.status === "COMPLIANCE" &&
            <DropDownItem text="Onboard Driver" loading={loadingOnboarding}
                          onclick={() => onboardDriver(info.row.original)}/>
          }
        </DropDown>
      </div>
    )
  })

  const columns: ColumnDef<IDriverLead, string>[] = useMemo(() => [...DriverLeadsColumn, actionColumn], []);

  const updateLead = async (lead: IDriverLead): Promise<void> => {
    setLead(lead)
    setUpdateLeadInfoRender(true)
  }

  const postReport = async (lead: IDriverLead): Promise<void> => {
    setLead(lead)
    setPostMessageRender(true)
  }

  const viewLeadStatus = async (lead: IDriverLead): Promise<void> => {
    setLead(lead)
    setLeadCanvasRender(true)
  }

  const onboardDriver = async (lead: IDriverLead): Promise<void> => {
    try {
      await onboardLeadMutation(`onboard/${lead.id}`).unwrap();
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  const searchLeads = (searchParams: string): void => {
    console.log(searchParams)
  }

  const updateLeadInfoModal = updateLeadInfoRender &&
    <UpdateLeadModal driverLead={lead} onchange={() => setUpdateLeadInfoRender(false)}/>

  const postMessageModal = postMessageRender &&
    <PostMessageModal driverLead={lead} onchange={() => setPostMessageRender(false)}/>

  const leadStatusCanvas = leadCanvasRender &&
    <LeadStatusCanvas updates={lead.acquisition_updates} onchange={() => setLeadCanvasRender(false)}/>

  return (
    <PageLayout active={MenuType.SUPPLY} title="Supply Service - Drivers Leads" loading={isLoading}>
      {postMessageModal}
      {leadStatusCanvas}
      {updateLeadInfoModal}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header custom-card-header">
                <div>
                  <h4 className="card-title">Drivers Leads</h4>
                  <p className="card-title-desc">List of acquired drivers generated leads</p>
                </div>
                <TableFilter searchFn={searchLeads}/>
              </div>
              <div className="card-body">
              <DataTable columns={columns} data={isLoading ? [] : leads.data} size={size} pageIndex={pageIndex} loadingState={isFetching}
                           count={isLoading ? 0 : leads.count} setPageIndex={(index: number) => setPageIndex(index)}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default DriverLeads;
