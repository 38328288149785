import {ChangeEvent, useState} from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import {Col, Row} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {SelectStyles} from "../../utils/helpers/selectStyles";
import {LoanTypes, RepaymentDuration} from "../../mocks/LoanEngine";
import {validateIfFloat, validateIfInteger} from "../../utils/services/validator.service";
import {useAddLoanConfigurationMutation, useUpdateLoanConfigurationMutation} from "../../store/slices/backoffice.slice";

type Props = {
  initialData?: ILoanConfig,
  onchange: () => void
}
const LoanConfigurationModal = ({onchange, initialData}: Props) => {
  const selectStyles = SelectStyles();
  const [show, setShow] = useState<boolean>(true);
  const [loanCap, setLoanCap] = useState((initialData && initialData.loan_cap) ? initialData.loan_cap : "");
  const [interest, setInterest] = useState((initialData && initialData.interest_rate) ? initialData.interest_rate : "");
  const [loanType, setLoanType] = useState<ILoanType | undefined>((initialData && initialData.loan_type) ? initialData.loan_type : undefined);
  const [repaymentDuration, setRepaymentDuration] = useState((initialData && initialData.repayment_duration) ? initialData.repayment_duration : "");
  const [repaymentUnit, setRepaymentUnit] = useState((initialData && initialData.repayment_unit) ? initialData.repayment_unit : RepaymentDuration[0].value);

  const [addLoanConfiguration, {isLoading}] = useAddLoanConfigurationMutation();
  const [updateLoanConfiguration, {isLoading: isUpdateLoading}] = useUpdateLoanConfigurationMutation();
  const isInvalid = !loanCap || !loanType || !interest || !repaymentDuration || !repaymentUnit || isLoading || isUpdateLoading;

  const handleClose = () => {
    setShow(false);
    onchange();
  }

  const handleAddLoanConfiguration = async () => {
    try {
      const payload: Partial<ILoanConfig> = {
        loan_cap: loanCap,
        loan_type: loanType,
        interest_rate: interest,
        repayment_unit: repaymentUnit,
        repayment_duration: repaymentDuration.toString()
      }
      if (initialData && initialData.id) {
        delete payload.loan_type;
        await updateLoanConfiguration({id: initialData.id, body: payload}).unwrap();
      } else await addLoanConfiguration(payload).unwrap()
      onchange()
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>{(initialData && initialData.id) ? "Edit" : "Add"} Loan Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} className="mb-3">
            <label htmlFor="loan_type" className="form-label">Top-Up Type</label>
            <Select options={LoanTypes}
                    value={LoanTypes.filter(option => option.value === loanType)}
                    isClearable={false}
                    minMenuHeight={250}
                    styles={selectStyles}
                    menuPlacement="bottom"
                    name="loan_type"
                    isDisabled={!!(initialData && initialData.id)}
                    placeholder="Select Top-up Type..."
                    onChange={option => setLoanType(option?.value)}/>
          </Col>
          <Col md={6} className="mb-3">
            <label htmlFor="loan_cap" className="form-label">Top-Up Cap</label>
            <input type="number" className="form-control" name="loan_cap"
                   placeholder="Enter Maximum Amount Payable" value={loanCap}
                   onKeyDown={validateIfFloat}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setLoanCap(e.target.value)}
            />
          </Col>
          <Col md={6} className="mb-3">
            <label htmlFor="interest_rate" className="form-label">Transaction Rate (%)</label>
            <input type="number" className="form-control" name="interest_rate"
                   placeholder="Enter Transaction Rate" value={interest}
                   onKeyDown={validateIfFloat}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => {
                     if (Number(e.target.value) > 100) return e.preventDefault();
                     setInterest(e.target.value)
                   }}
            />
          </Col>
          <Col md={6} className="mb-3">
            <label htmlFor="repayment_duration" className="form-label">Repayment Duration</label>
            <input type="number" className="form-control" name="repayment_duration"
                   placeholder="Enter Repayment Duration" value={repaymentDuration}
                   onKeyPress={validateIfInteger}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setRepaymentDuration(e.target.value)}
            />
          </Col>
          <Col md={6} className="mb-3">
            <label htmlFor="repayment_unit" className="form-label">Repayment Unit</label>
            <Select options={RepaymentDuration}
                    value={RepaymentDuration.filter(option => option.value === repaymentUnit)}
                    isClearable={false}
                    minMenuHeight={250}
                    styles={selectStyles}
                    menuPlacement="bottom"
                    name="repayment_unit"
                    placeholder="Select repayment unit..."
                    onChange={option => setRepaymentUnit(option?.value)}/>
          </Col>
        </Row>
        <div className="mt-4 float-end">
          <button className="btn btn-primary" disabled={isInvalid} onClick={handleAddLoanConfiguration}>
            {(initialData && initialData.id) ? "Update" : "Add"} Configuration {(isLoading || isUpdateLoading) &&
						<i className="bx bx-loader bx-spin"/>}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LoanConfigurationModal;
