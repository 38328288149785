import {ReactElement} from "react";
import {BrowserRouter as Router, Navigate, Outlet, Route, Routes} from "react-router-dom";
import {Toaster} from "react-hot-toast";

import TokenHelper from "./utils/helpers/tokenHelper";
import {MenuType, SubMenuType} from "./utils/helpers/constants";

import Login from "./pages/authentication/Login";
import LockScreen from "./pages/authentication/LockScreen";
import Home from "./pages/dashboard/home/Home";
import SijuHome from "./pages/dashboard/home/SijuHome";
import Standard from "./pages/dashboard/trips/Standard";
import TripInfo from "./pages/dashboard/trips/TripInfo";
import DriverProfile from "./pages/dashboard/supply/DriverProfile";
import DriverLeads from "./pages/dashboard/supply/DriverLeads";
import DriverPerformance from "./pages/dashboard/supply/DriverPerformance";
import DocumentExpiry from "./pages/dashboard/supply/DocumentExpiry";
import Messaging from "./pages/dashboard/messaging/Messaging";
import CreateMessaging from "./pages/dashboard/messaging/CreateMessaging";
import Voucher from "./pages/dashboard/voucher/Voucher";
import VoucherInfo from "./pages/dashboard/voucher/VoucherInfo";
import CreateVoucher from "./pages/dashboard/voucher/CreateVoucher";
import Docs from "./pages/dashboard/docs/Docs";
import Staff from "./pages/dashboard/staff/Staff";
import Content from "./pages/dashboard/docs/Content";
import Services from "./pages/dashboard/saas/Services";
import Signatories from "./pages/dashboard/staff/Signatories";
import Organisations from "./pages/dashboard/saas/Organisations";
import ServiceInfo from "./pages/dashboard/saas/misc/ServiceInfo";
import TenantPermissions from "./pages/dashboard/saas/TenantPermissions";
import SupportedCountries from "./pages/dashboard/saas/SupportedCountries";
import OrganisationProfile from "./pages/dashboard/saas/OrganisationProfile";
import LoanRequests from "./pages/dashboard/supply/LoanRequests";
import LoanRequestInfo from "./pages/dashboard/supply/LoanRequestInfo";
import LoanConfiguration from "./pages/dashboard/supply/LoanConfiguration";
import WebsiteLeads from "./pages/dashboard/leads/WebsiteLeads";

import Error404 from "./pages/misc/Error404";







const App = () => {

  const AuthenticatedRoutes = (): ReactElement => {
    if (TokenHelper.authenticationValid()) return <Outlet/>
    else return <Navigate to="auth/login" replace/>;
  }

  return (
    <Router>
      <Toaster containerStyle={{zIndex: '99999999'}}/>
      <Routes>
        <Route path="auth/login" element={<Login/>}/>
        <Route element={<AuthenticatedRoutes/>}>
          {['/', MenuType.HOME, 'dashboard'].map((path: string) => <Route path={path} key={path} element={<Home/>}/>)}
          <Route path={MenuType.SIJU} element={<SijuHome/>}/>
          <Route path={MenuType.TRIPS}>
            <Route path="standard" element={<Standard/>}/>
            <Route path=":reference" element={<TripInfo/>}/>
          </Route>
          <Route path={MenuType.SUPPLY}>
            <Route path="driver/:reference" element={<DriverProfile/>}/>
            <Route path="leads" element={<DriverLeads/>}/>
            <Route path="performance" element={<DriverPerformance/>}/>
            <Route path="expiring-documents" element={<DocumentExpiry/>}/>
            <Route path={SubMenuType.LOAN} element={<Outlet/>}>
              <Route path="configuration" element={<LoanConfiguration/>}/>
              <Route path="requests" element={<LoanRequests/>}/>
              <Route path="requests/:ref" element={<LoanRequestInfo/>}/>
            </Route>
          </Route>
          <Route path={MenuType.SAAS}>
            <Route path="supported-countries" element={<SupportedCountries/>}/>
            <Route path="organisations" element={<Organisations/>}/>
            <Route path="organisation/:reference" element={<OrganisationProfile/>}/>
            <Route path="services" element={<Services/>}/>
            <Route path="service/:reference" element={<ServiceInfo/>}/>
            <Route path="permissions" element={<TenantPermissions/>}/>
          </Route>
          <Route path={MenuType.MESSAGING}>
            <Route path="" index element={<Messaging/>}/>
            <Route path="create" element={<CreateMessaging/>}/>
          </Route>
          <Route path={MenuType.DOCS}>
            <Route path="topics" element={<Docs/>}/>
            <Route path="content/:topic/:slug" element={<Content/>}/>
          </Route>
          <Route path={MenuType.VOUCHER}>
            <Route path="" element={<Voucher/>}/>
            <Route path=":reference" element={<VoucherInfo/>}/>
            <Route path="create" element={<CreateVoucher/>}/>
          </Route>
          <Route path={MenuType.WEBSITELEADS} element={<WebsiteLeads/>}/>
          <Route path={MenuType.STAFF} element={<Staff/>}/>
          <Route path="signatories" element={<Signatories/>}/>
          <Route path="auth/lock-screen" element={<LockScreen/>}/>
        </Route>
        <Route path="*" element={<Error404/>}/>
      </Routes>
    </Router>
  );
}

export default App;
