import {ChangeEvent, useState} from "react";
import toast from "react-hot-toast";
import {Col, Row} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import {IActionPrompt} from "../../interfaces/configs";
import {ActionPromptEnum} from "../../interfaces/enums";
import {ActionPromptMap} from "../../utils/helpers/mappers";
import {Loading} from "../../utils/helpers/constants";
import {queryErrorHandler, toTitleCase} from "../../utils/helpers/logicHelper";
import {
  useApproveLoanRequestMutation,
  useRejectLoanRequestMutation,
  useRepayLoanRequestMutation
} from "../../store/slices/backoffice.slice";

type Props = {
  type: ActionPromptEnum,
  props?: string,
  confirmation: string,
  onchange: () => void,
}

const ActionPromptModal = ({type, props, confirmation, onchange}: Props) => {
  const actionProps: IActionPrompt = ActionPromptMap[type];
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState<string>("");

  const [repayLoanRequest] = useRepayLoanRequestMutation();
  const [rejectLoanRequest] = useRejectLoanRequestMutation();
  const [approveLoanRequest] = useApproveLoanRequestMutation();

  const isInvalid: boolean = text !== confirmation;


  const handleClose = (): void => {
    setShow(false);
    onchange();
  };

  const completeAction = () => {
    toast.success(actionProps.successMessage);
    handleClose();
  }

  const handleChangeRequest = async (event: any): Promise<void> => {
    setLoading(true);
    event.preventDefault();

    try {
      let responseData;

      if (props && type) {
        if (type === ActionPromptEnum.REPAY_TOP_UP_REQUEST) responseData = await repayLoanRequest(props).unwrap();
        if (type === ActionPromptEnum.REJECT_TOP_UP_REQUEST) responseData = await rejectLoanRequest(props).unwrap();
        if (type === ActionPromptEnum.APPROVE_TOP_UP_REQUEST) responseData = await approveLoanRequest(props).unwrap();
      } else handleClose();

      if (responseData && responseData.data && responseData.data.status && responseData.data.status === Loading.NO_CONTENT) completeAction();
      else if (responseData && responseData.data && responseData.data.status && responseData.data.status === Loading.SUCCESS) completeAction();
      else handleClose();
    } catch (err: any) {
      const errMessage = queryErrorHandler(toTitleCase(type) , err);
      toast.error(errMessage);
    }

    setLoading(false);
  }

  return (
    <Modal show={show} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton><Modal.Title>{actionProps.title}</Modal.Title></Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <p>{actionProps.message} - <b>{confirmation}</b></p>
            <p className="mb-3">
              You can send in a support request about this information, and may choose not to continue with this action.
            </p>
            <p className="mb-3 text-danger fw-bold">This action cannot be undone</p>
            <div className="mb-5">
              <input type="text" className="form-control ht-45-f" value={text} placeholder={actionProps.placeholder}
                     onChange={(event: ChangeEvent<HTMLInputElement>) => setText(event.target.value)}/>
            </div>
            <div className="d-flex justify-content-end gap-2">
              <button className="btn btn-outline-danger" onClick={handleClose}>
                Cancel
              </button>
              <button className="btn btn-success" disabled={isInvalid} onClick={handleChangeRequest}>
                {actionProps.actionText} {loading && <i className="bx bx-loader-circle bx-spin"/>}
              </button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
export default ActionPromptModal;
