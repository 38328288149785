import {Fragment, useState} from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import moment from "moment/moment";
import {LoanRequestMap} from "../../utils/helpers/mappers";

type Props = {
  updates: ILoanJourney[] | undefined,
  onchange: () => void
}

const LoanStatusCanvas = ({updates, onchange}: Props) => {
  const [show, setShow] = useState<boolean>(true);
  const handleClose = () => {
    setShow(false);
    onchange();
  }

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Loan Acquisition Updates</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="dash-timeline">
          <ul className="task-list mb-0">
            {updates && updates.map((item: ILoanJourney, index: number) =>
              <li key={index}>
                <i className="task-icon"/>
                <span>{item.message}</span>
                {item.administrative && Object.entries(item.administrative).length > 0 &&
									<Fragment>
										<p className="my-1">
                      Approved by: {item.administrative?.first_name} {item.administrative?.last_name}
                    </p>
                    <p className="my-1">
                      Approver email: {item.administrative?.email}
                    </p>
                    <p className="my-1">
                      Approver phone: {item.administrative?.phone}
                    </p>
									</Fragment>
                }
                <p className="text-muted font-size-12">
                  <span className={`badge rounded-pill m-1 
                    ${(LoanRequestMap[item.status]) ? LoanRequestMap[item.status].fill : "bg-secondary"} py-1`}>
                    {LoanRequestMap[item.status].label}
                  </span> | &nbsp; {moment(item.created_at).format('LLL')}
                </p>
              </li>
            )}

          </ul>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default LoanStatusCanvas;
