import {ChangeEvent, useState} from "react";
import Modal from 'react-bootstrap/Modal';

interface Props {
  deleteProps: IDeleteProps
  onchange: () => void
  actionPrompt: () => void
}

const DeleteEntityModal = ({deleteProps, onchange, actionPrompt}: Props) => {

  const [text, setText] = useState<string>("");
  const [show, setShow] = useState<boolean>(true);
  const isValidated: boolean = text !== deleteProps.confirmation;

  const handleClose = (): void => {
    setShow(false)
    onchange()
  }

  const handleSave = (): void => {
    actionPrompt();
    onchange()
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton><Modal.Title>{deleteProps.title}</Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 mb-3">
            {deleteProps.errorMsg} - <b>{deleteProps.confirmation}</b>
          </div>
          <div className="col-md-12 mb-3">
            You can send in a support request about this information, and may choose not to continue with this action.
          </div>
          <div className="col-md-12 mb-3 text-danger fw-bold">
            This action cannot be undone
          </div>
          <div className="col-md-12 mb-3">
            <input type="text" className="form-control ht-45-f" value={text} placeholder={deleteProps.confirmMsg}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => setText(event.target.value)}/>
          </div>
          <div className="col-md-12 mt-3 mb-3 text-end">
            <button onClick={handleSave} disabled={isValidated} className="btn btn-md btn-outline-danger ms-1">
              Confirm Delete
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteEntityModal;
