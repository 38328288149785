import {Fragment} from "react";
import moment from "moment/moment";
import {Card, Col, Row} from "react-bootstrap";
import {InputType} from "../../../../interfaces/enums";
import {InputDynamicFields} from "../../../../interfaces/configs";
import {LoanRequestMap} from "../../../../utils/helpers/mappers";
import {formatAmountToString, toTitleCase} from "../../../../utils/helpers/logicHelper";


type Props = {
  request: ILoanRequest,
  dynamicFields: InputDynamicFields[]
}

const LoanRequestBreakdown = ({request, dynamicFields}: Props) => (
    <Col md={7} xl={8} className="h-auto">
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex justify-content-between border-bottom pb-3">
            <h5 className="mb-1">{request?.reference ?? ""}</h5>
            <span className={`badge rounded-pill m-1 
                    ${(request && LoanRequestMap[request.status]) ? LoanRequestMap[request.status].fill : "bg-secondary"} font-size-14`}>
                      {(request && request.status) ? LoanRequestMap[request?.status].label : "INITIATED"}
                    </span>
          </div>

          <Row className="mt-4">
            <Col md={12} lg={6} className="mb-4">
              <span className="fw-bold">Top-Up Use:</span>
              <span
                className="ms-2">{formatAmountToString(request.loan_config?.loan_type) ?? 0}</span>
            </Col>
            <Col md={12} lg={6} className="mb-4">
              <span className="fw-bold">Transaction Rate:</span>
              <span
                className="ms-2">{formatAmountToString(request?.loan_config?.interest_rate) ?? 0} %</span>
            </Col>
            <Col md={12} lg={6} className="mb-4">
              <span className="fw-bold">Top-up Amount:</span>
              <span className="ms-2">{formatAmountToString(request?.amount ?? 0)}</span>
            </Col>
            <Col md={12} lg={6} className="mb-4">
              <span className="fw-bold">Repayment Amount:</span>
              <span className="ms-2">{formatAmountToString(request?.repayment_amount ?? 0)}</span>
            </Col>
            <Col md={12} lg={6} className="mb-4">
              <span className="fw-bold">Repayment Due Date:</span>
              <span className="ms-2">{moment(request?.due_date).format('lll') ?? "-"}</span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between border-bottom pb-3">
            <h5 className="mb-1">More User Information</h5>
          </div>
          <Row className="mt-4">
            {(request && request.driver && request.driver.dynamic_fields) &&
              dynamicFields.map((item) =>
                <Fragment key={item.key}>
                  {(item.key in request.driver.dynamic_fields && (item.type !== InputType.FILE && item.type !== InputType.BOOLEAN)) &&
										<Col md={12} lg={6} className="mb-4">
											<span className="fw-bold">{toTitleCase(item.key)}</span>
											<span className="ms-2">
                            {(item.type !== InputType.DATE && request.driver.dynamic_fields[item.key]) ? toTitleCase(request.driver.dynamic_fields[item.key])
                              : (item.type === InputType.DATE && request.driver.dynamic_fields[item.key] && moment(request.driver.dynamic_fields[item.key]).isValid())
                                ? moment(request.driver.dynamic_fields[item.key]).format('D-MMMM-YYYY')
                                : "-"
                            }
                          </span>
										</Col>
                  }
                </Fragment>
              )}
          </Row>
        </Card.Body>
      </Card>
    </Col>

  )

export default LoanRequestBreakdown;
