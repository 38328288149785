import moment from "moment";

export const updateSideNav = (state: boolean): void => {
  const body: HTMLElement = document.body;
  if (body && state) body.classList.add("sidebar-enable");
  else if (body && !state) body.classList.remove("sidebar-enable");
}

export const getYear = () => {
  const date: Date = new Date();
  return date.getFullYear();
};

export const toTitleCase = (str: string | undefined): string => {
  if (!str || str.length === 0) return "";
  str = (/[_&\\/#, +()$~%.'":*?<>{}-]/g).test(str) ? str.replaceAll(/[_&\\/#, +()$~%.'":*?<>{}-]/g, ' ') : str;
  return str.trim().split(' ').map(word => (
    word[0] ? word[0].toUpperCase() + word.substring(1).toLowerCase() : word + word.substring(1).toLowerCase()
  )).join(' ')
}

export const validateURLPath = (str: string): string => {
  if (!str || str.length === 0) return "";
  let absolutePath: boolean = /^(www|http|https)/.test(str);
  let slashPath: boolean = /^(\/)/.test(str);
  return absolutePath ? str : slashPath ? str.replace(/\//, '') : str;
}

export const generateSlug = (str: string): string => {
  return str.replace(/\s+/g, '-').toLowerCase();
}

export const verifyTimestamp = (dateString: string): Date => {
  let isDateString: boolean = moment(dateString, 'DD-MM-YYYY', true).isValid()
  let isIsoDateString: boolean = moment(dateString, '', true).isValid()
  if (!isIsoDateString || !isDateString) return new Date();
  else if (isIsoDateString) return new Date(dateString);
  else if (isDateString) {
    let [day, month, year] = dateString.split('-')
    return new Date(+year, +month - 1, +day)
  } else return new Date();
}

export const formatAmountToString = (data: string | number | undefined): string => {
  if(!data) return '0.00';

  const format: string | number = !data ? "0.00" : data;
  if (format.toString() === "0.00") return format.toLocaleString();
  else if (format.toString().includes(",")) {
    const newFormat: string = format.toString().replace(/,/g, "");
    const formattedNumber: number = parseFloat(newFormat);
    return formattedNumber.toLocaleString();
  } else if (format.toString().includes(".")) {
    const formatValue: string = truncateDecimalValue(format);
    return formatValue.toLocaleString();
  } else return `${format.toLocaleString()}`;
}

export const truncateDecimalValue = (value: string | number): string => {
  value = value.toString();
  const numberValue: string = value.split(".")[0];
  const suffixedNumber: string = value.split(".")[1].substring(0, 2);
  const decimalCount: number = value.split(".")[1].length;
  const prefixedNumber: string = Number(numberValue).toLocaleString();
  if (decimalCount > 2) value = `${prefixedNumber}.${suffixedNumber}`;
  else if (decimalCount < 2) value = `${prefixedNumber}.${suffixedNumber}0`;
  else value = `${prefixedNumber}.${suffixedNumber}`;
  return value;
}

export const truncateText = (text: string, size: number): string => {
  let truncatedText: string
  if (!text) return "";
  if (text.length < size) truncatedText = text;
  else if (text.length === size) truncatedText = `${text.replace(/<[^>]*>?/gm, " ").slice(0, size)}`;
  else if (text.length > size) truncatedText = `${text.replace(/<[^>]*>?/gm, " ").slice(0, size)}...`;
  else truncatedText = '-';
  return truncatedText
}

export const formatTripDetails = (trip: ITrip) => {
  let newArray: { [key: string]: string }[] = [], totalExtrasCharge: number = 0;
  let currency: string = (!trip.quote.currency) ? "NGN" : trip.quote.currency

  if (!trip["business_tripset"]) {
    for (const eachItem in trip["extras"]) {
      if (trip.extras[eachItem] !== 0) {
        if (eachItem in trip.quote) {
          let name: string = toTitleCase(eachItem)
          let value: string = `${formatAmountToString(trip.quote[eachItem])} ${currency}`
          let entry: { [key: string]: string } = {[name]: value}
          newArray.push(entry)
          totalExtrasCharge += Number(trip.quote[eachItem])
        }
      }
    }
    let insuranceValue: string = `${formatAmountToString(trip.quote["estimated_insurance"] ?? '')} ${currency}`
    let insuranceEntry: { [key: string]: string } = {"Insurance": insuranceValue}
    newArray.push(insuranceEntry);
  }

  let tripAmount: number = Number(trip.quote["total_charge"]) - (Number(totalExtrasCharge) + Number(trip.quote["vat_value"]))
  if (trip.quote["estimated_insurance"]) tripAmount -= Number(trip.quote["estimated_insurance"])
  let tripKey: string = `Trip to destination (${trip.category_size.tonnage} tonne ${trip.category_size.category})`
  let tripValue: string = `${formatAmountToString(tripAmount)} ${currency}`
  let tripEntry: { [key: string]: string } = {[tripKey]: tripValue}
  let vatEntry: { [key: string]: string } = {"VAT": `${formatAmountToString(trip.quote["vat_value"])} ${currency} `}
  newArray.push(tripEntry)
  newArray.push(vatEntry)

  if (trip.quote['discount_amount']) {
    let discountEntry: { [key: string]: string } = {"Discount": `- ${trip.quote['discount_amount']} ${currency}`}
    newArray.push(discountEntry);
  }

  return newArray;
}

export const formatRevInsights = (data: any): IChart[] => {
  let newArray: IChart[] = []
  for (let each in data.gross) newArray.push({name: each, gross: data.gross[each]})
  for (let each of newArray) each.net = data.net[each.name]
  for (let each of newArray) each.commission = data.commission[each.name]
  return newArray
}

export const formatAdvInsights = (data: any): IChart[] => {
  let newArray: IChart[] = []
  for (let each in data.volume) newArray.push({name: each, volume: data.volume[each]})
  for (let each of newArray) each.commission = data.commission[each.name]
  return newArray
}

export const formatWithdrawalInsights = (data: any): IChart[] => {
  let newArray: IChart[] = []
  for (let each in data.successful) newArray.push({name: each, successful: data.successful[each]})
  for (let each of newArray) each.failed = data.failed[each.name]
  return newArray
}

export const formatTripsInsights = (data: any): string => {
  let total: number = 0;
  for (let each in data) total = Number(total) + Number(data[each])
  return formatAmountToString(total)
}

export const formatHealthChart = (data: any): IChart[] => {
  let newArray: IChart[] = []
  for (let each in data.transactions.props) newArray.push({name: each, props: data.transactions.props[each]})
  for (let each of newArray) {
    each.commission = Number(data.transactions.commission[each.name]) + Number(data.advanced_payments.commission[each.name])
      + Number(data.insurance.commission[each.name]) + Number(data.sms.commission[each.name])
  }
  return newArray
}

export const formatCommissionChart = (data: any): IChart[] => {
  let newArray: IChart[] = [], trips: number = 0, commission: number = 0, insurance: number = 0, sms: number = 0;
  for (let each in data.transactions.commission) trips = Number(trips) + Number(data.transactions.commission[each])
  for (let each in data.advanced_payments.commission) commission = Number(commission) + Number(data.advanced_payments.commission[each])
  for (let each in data.insurance.commission) insurance = Number(insurance) + Number(data.insurance.commission[each])
  for (let each in data.sms.commission) sms = Number(sms) + Number(data.sms.commission[each])

  newArray.push({name: 'Transactions', value: trips, fill: "#4c47b1"})
  newArray.push({name: 'Trip Advances', value: commission, fill: "#211c7c"})
  newArray.push({name: 'Insurance (VAS)', value: insurance, fill: "#800d35"})
  newArray.push({name: 'SMS (VAS)', value: sms, fill: "#76781c"})
  return newArray
}

export const formatCommissionSummary = (data: any): IChart[] => {
  let newArray: IChart[] = [], trans: number = 0, trips: number = 0, commission: number = 0, insurance: number = 0;
  let advVolume: number = 0, sms: number = 0;

  for (let each in data.transactions.commission) trans = Number(trans) + Number(data.transactions.commission[each])
  for (let each in data.transactions.trip) trips = Number(trips) + Number(data.transactions.trip[each])
  for (let each in data.advanced_payments.commission) commission = Number(commission) + Number(data.advanced_payments.commission[each])
  for (let each in data.advanced_payments.volume) advVolume = Number(advVolume) + Number(data.advanced_payments.volume[each])
  for (let each in data.insurance.commission) insurance = Number(insurance) + Number(data.insurance.commission[each])
  for (let each in data.sms.commission) sms = Number(sms) + Number(data.sms.commission[each])

  let adv: string = `₦${formatAmountToString(advVolume)} Processed`;
  let total: number = trans + commission + insurance + sms;
  newArray.push({name: 'Transactions', value: trans, fill: "bg-primary", sub: `${trips} Trips`})
  newArray.push({name: 'Advanced Payments', value: commission, fill: "bg-secondary", sub: adv})
  newArray.push({name: 'Insurance (VAS)', value: insurance, fill: "bg-info", sub: `${trips} Trips`})
  newArray.push({name: 'SMS (VAS)', value: sms, fill: "bg-dark", sub: ""})
  newArray.push({name: 'Total Commission', value: total, fill: "bg-success", sub: ""})
  return newArray
}

export const formatWithdrawalStatus = (data: any): IChart[] => {
  let newArray: IChart[] = [], totalSuccessful: number = 0, totalFailed: number = 0;
  for (let each in data.successful) totalSuccessful = Number(totalSuccessful) + Number(data.successful[each])
  for (let each in data.failed) totalFailed = Number(totalFailed) + Number(data.failed[each])
  newArray.push({name: 'Successful', value: totalSuccessful, fill: "#18b32a"})
  newArray.push({name: 'Failed', value: totalFailed, fill: "#7e0839"})
  return newArray
}

export const queryErrorHandler = (type: string, response?: any) => {
  if (!response) return `You cannot ${type.toLowerCase()} at this time, Please try again later.`;

  if (response && response.data && response.data.message) return response.data.message;
  else return`You cannot ${type.toLowerCase()} at this time, Please try again later.`;
};
