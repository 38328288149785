import {useMemo, useState} from "react";
import {Location, NavigateFunction, useLocation, useNavigate} from "react-router-dom";
import {CellContext, ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";

import PageLayout from "../../../components/layouts/PageLayout";
import TableFilter from "../../../components/dataTable/TableFilter";
import DataTable from "../../../components/dataTable/DataTable";
import {MenuType} from "../../../utils/helpers/constants";
import {useGetVouchersQuery} from "../../../store/slices/backoffice.slice";
import {VoucherColumns} from "../../../components/dataTable/VoucherColumn";

const Voucher = () => {

  const navigate: NavigateFunction = useNavigate(), location: Location<any> = useLocation();

  const [size] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [filterParams, setFilterParams] = useState<string>("");
  const [voucherList, setVoucherList] = useState<IVoucher[]>([]);
  const {data: vouchers, isLoading, isFetching} = useGetVouchersQuery(`?size=${size}&page=${pageIndex}${filterParams}`);
  const column: ColumnHelper<IVoucher> = createColumnHelper<IVoucher>();
  const state: IDynamicFields = {prev: location.pathname, pageIndex};


  const navigateAction = (reference: string) => navigate(`/vouchers/${reference}`, {state: {...state, reference}})
  const actionColumn: ColumnDef<IVoucher, string> = (column.accessor('id', {
    header: () => <span>Action</span>,
    enableSorting: false,
    cell: (info: CellContext<IVoucher, string>) => (
      <span className="text-center pointer">
        <button className="btn btn-sm btn-primary" onClick={() => navigateAction(info.row.original.voucher_number)}>
          View Voucher
        </button>
      </span>
    )
  }))

  const columns: ColumnDef<IVoucher, string>[] = useMemo(() => [...VoucherColumns, actionColumn], []);

  useMemo(() => {
    if (vouchers && vouchers.data) {
      setCount(vouchers.count);
      setVoucherList(vouchers.data);
    }
  }, [vouchers]);

  const searchVoucher = (searchParams: string): void => {
    const isNotNumber: boolean = isNaN(Number(searchParams));
    if (searchParams.length < 2) {
      setFilterParams("");
      setCount(vouchers.count);
      setVoucherList(vouchers.data);
    } else {
      const filtered: IVoucher[] = voucherList.filter((data: IVoucher): boolean => JSON.stringify(data).toLowerCase().indexOf(searchParams.toLowerCase()) !== -1);
      if (filtered.length > 0) {
        setVoucherList(filtered);
        setCount(filtered.length);
      } else {
        if (searchParams.length > 2 && isNotNumber) setFilterParams(`&payee=${searchParams}`);
        else if (searchParams.length === 7 && !isNotNumber) setFilterParams(`&voucher_number=${searchParams}`);
        else if (searchParams.length === 10 && !isNotNumber) setFilterParams(`&payee_bank_account=${searchParams}`);
      }
    }
  }

  return (
    <PageLayout active={MenuType.VOUCHER} title="Payment Vouchers" loading={isLoading} searchType="VOUCHER"
                navSearch={searchVoucher}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6"/>
          <div className="col-md-6">
            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
              <button onClick={() => navigate('/vouchers/create')} className="btn btn-primary">
                <i className="bx bx-plus me-1"></i> Create Voucher
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Payment Vouchers</h4>
                <p className="card-title-desc">List of available payment vouchers</p>
              </div>
              <div className="card-body">
                <TableFilter searchFn={searchVoucher}/>
                <DataTable columns={columns} data={voucherList} size={size} pageIndex={pageIndex}
                           loadingState={isFetching}
                           count={count} setPageIndex={(index: number) => setPageIndex(index)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Voucher;
